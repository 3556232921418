<template>
  <transition-group
  v-if="visible"
  name="fade-transition"
  appear
  >
    <template
    v-for="rating in ratings"
    >
      <v-icon
      v-if="!jokeRating || jokeRating.val === rating.value"
      :key="rating.value"
      :class="rating.class"
      :color="jokeRating && jokeRating.val === rating.value ? 'yellow' : ''"
      class="mx-2"
      :size="jokeRating && jokeRating.val === rating.value ? '60' : '40'"
      dark
      @click="rate(rating.value)"
      >
        {{ rating.icon }}
      </v-icon>
    </template>
  </transition-group>
</template>

<script>
  export default {
    name: 'RatingBlock',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      jokeRating: {
        type: Object,
        default: null
      }
    },
    data: function() {
      return {
        ratings: [
          {
            icon: 'mdi-emoticon-frown-outline',
            value: 3,
            class: ''
          },
          {
            icon: 'mdi-emoticon-confused-outline',
            value: 6,
            class: ''
          },
          {
            icon: 'mdi-emoticon-neutral-outline',
            value: 9,
            class: ''
          },
          {
            icon: 'mdi-emoticon-lol-outline',
            value: 12,
            class: 'rotate'
          }
        ]
      };
    },
    methods: {
      rate(val) {
        this.$emit('rate', val);
      }
    }
  };
</script>

<style scoped>
.rotate {
  /*animation-name: bounce-in;*/
  animation-iteration-count: infinite;
  animation: rotate 1.5s infinite;
  animation-delay: .7s;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(80deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
