import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/firestore';
import { FB_VAPID_KEY } from '../config';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBGuLlXRNpNEopqMPrIi7pKR2Y0K7dOmMM',
  authDomain: 'app-knock-knock.firebaseapp.com',
  projectId: 'app-knock-knock',
  storageBucket: 'app-knock-knock.appspot.com',
  messagingSenderId: '1021891079996',
  appId: '1:1021891079996:web:7e2fae8a214b94900b3839',
  measurementId: 'G-MZ3KZTT6Y0'
};

firebase.initializeApp(firebaseConfig);

try {
  const messaging = firebase.messaging();
  messaging.getToken({ vapidKey: FB_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log('token', currentToken);

        // TODO - send token to server
        // usersApi.registerFCMToken(currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

  messaging.onMessage((payload) => {
    const msg = `<div><h4>${payload.notification.title}</h4>${payload.notification.body}</div>`;

    window.app.$notify({
      group: 'generic',
      text: msg,
      duration: 4000
    });
  });
} catch (e) {
  console.log(e);
}
