<template>
  <v-container
  grid-list-md
  text-xs-center
  fluid
  fill-height
  class="px-1 card-cover"
  :class="containerClass"
  :style="containerStyle"
  >
    <v-layout
    style="max-width: 1000px; z-index: 1"
    class="ma-auto"
    >
      <v-flex>
        <slot/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  // const bgImage = 'http://www.ishift.co.il/img/bg-pattern.png';
  // eslint-disable-next-line max-len
  // const bgImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAYAAADdY439AAAEvUlEQVR4Xu2cPW/bMBCGJSeAYXl11nq1g2yaO/c3d+6sLYi9pmu9SoaBQCpeQTSuDCV+i2p7XoJYskU+uvfuSN05zxK/np+fv769vf1INYyyLIvr9Vp2XfczPxwO37Isu+R5fjmdTu9zDyoVjOPxuO+6bkfnm+MfcWC1WhWr1eqyXq9/VVV1mQPMnDAU83yvqqoR8+xh0Bcl9vj42KzX6z8+EBpQbBhlWe5ut9tT27a7tm2b7XaL+Shv9CcYYrLQUtM0T13XHWLKKAYMjP12u+0FAFMXMAqDWgDo1nW9h4xA1/TLTawoJAxq1RhjURSQ+10GuvEYwYgpI18Y1A/oZBAchspi8J6rf3GBIfuBUJZqbRljdHGH8jz/YisjUxjEh/XhMBSAT6FVZz42x1X+ZUq7OhgvLy/Hj4+PQgCw9QM2Yw9mGfJFxZ2EteDYWP6CpO98Pn9X+aW5855oMKbyF+rphWXEjFim1jEbDEX+ssOdx/vwM/iLhCjLssYmHJpO1OS8RcAQA8ViKcX6SFx/NhhUBoP/6BeFWDFi1Yrj1+v1OOVfTO6uzzlRYZisC1TRhCZSsJaYESR2aBVrmns+4BNaZTAxZRTEMlQJkend1OUZxPH2q0/kHHC8MfyLFwzd/oCJfk1hjEWjkPsv1jCEHxBZoW84tIWhyl8eHh4KjMd1fWQVTeRsMqSJ+sKQLUZkvC5jNNnc6ZMjEQ5DJ0ShYEir6TCbO//atp9Y6ImNKe22nyIh+j83hGGmtnsQJlHC9JwYMtFdW7VHiscG1tFEdyHb4ylgSP6lXwb0D5FsBx/6/NQwMB+MYbPZVAxjgIHFIsNgGH+KXUiVLYMtgy1jNBCyTAgahsEw1Ephy2DLYMvQLqdYJiwTlgnLREuAZcIy0RoJRxOWCcuEZaIlsESZHA6H8nw+VzaDD31ucgdKS5zmLFVSgUwCQzzWkyp+66EiB8WzSTqiZoUh6spxV6ZKG8QDcJw3FJ7M8gA8OgxMrGmaLYpHXGo75qz4iwKDVvjgyT7u7uvr68nH4fkUz5leNxgMOlhRhWda6Wc6WHGea7uV7jreMEJU+ukGOXU85PWdYKSs8NGBkVo5rDoujWHM0QHkYyH0syP+pdb16E7CoPlAbD8QCoT8PTSpw7GpUshPMGRH6BIOY03M93vlxh654u9euWNS+e87mCV9XpHYvaPNY7PZnHJQcammXdIEXcZCwzQ+31c8YtWIf1w6hF0GsYTPEOtAG8hFtIf1lTuqZCZW4pQKxtQcR6NJrCwvBQRVdqxq3jHKM1KtIn3AuURFIxhSQnP/pYTUmzEqWLrwOQXYGoa8WJqrJXtqErCCuq77jkcRBFx62JxhyOkvfrSDdiXpUl8fCVBnL3rVkB/4NgYHgTEmo5B9YwLA8CsvfUekb/uVfEOCw6AXCLFLBQlkWQYZ7KkMYoT8qDAoGHQC4bdv8J7JeidFzjMbDOp4YeqqHxxJAYDesNlhjPmXtm0hh0toP2DjqJPCoNYyrBir0F2Rfx0MDHgpnUjcfDOYzyJkwpYhiZllQoAwDIahDnZsGWwZbBnaRJBlwjJhmbBMtARYJiwTrZFwNGGZsExYJloCLBOWidZIOJqwTJYvk99oQ2aizSFengAAAABJRU5ErkJggg==';

  import { bgImage } from '@/utils/assets.util';

  const bgImages = [
    {
      name: 'graphic',
      src: bgImage,
      // src: 'https://bestmediainfo.com/timthumb.php?src=/wp-content/uploads/2018/05/Stand-Up-Comedy-Kids_3.jpg&w=620&h=350&zc=1&q=100',
      class: 'bg-no-repeat bg-size-cover card-cover-darken'
    },
    {
      name: 'pattern',
      class: 'bg-repeat',
      // eslint-disable-next-line max-len
      src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAYAAADdY439AAAEvUlEQVR4Xu2cPW/bMBCGJSeAYXl11nq1g2yaO/c3d+6sLYi9pmu9SoaBQCpeQTSuDCV+i2p7XoJYskU+uvfuSN05zxK/np+fv769vf1INYyyLIvr9Vp2XfczPxwO37Isu+R5fjmdTu9zDyoVjOPxuO+6bkfnm+MfcWC1WhWr1eqyXq9/VVV1mQPMnDAU83yvqqoR8+xh0Bcl9vj42KzX6z8+EBpQbBhlWe5ut9tT27a7tm2b7XaL+Shv9CcYYrLQUtM0T13XHWLKKAYMjP12u+0FAFMXMAqDWgDo1nW9h4xA1/TLTawoJAxq1RhjURSQ+10GuvEYwYgpI18Y1A/oZBAchspi8J6rf3GBIfuBUJZqbRljdHGH8jz/YisjUxjEh/XhMBSAT6FVZz42x1X+ZUq7OhgvLy/Hj4+PQgCw9QM2Yw9mGfJFxZ2EteDYWP6CpO98Pn9X+aW5855oMKbyF+rphWXEjFim1jEbDEX+ssOdx/vwM/iLhCjLssYmHJpO1OS8RcAQA8ViKcX6SFx/NhhUBoP/6BeFWDFi1Yrj1+v1OOVfTO6uzzlRYZisC1TRhCZSsJaYESR2aBVrmns+4BNaZTAxZRTEMlQJkend1OUZxPH2q0/kHHC8MfyLFwzd/oCJfk1hjEWjkPsv1jCEHxBZoW84tIWhyl8eHh4KjMd1fWQVTeRsMqSJ+sKQLUZkvC5jNNnc6ZMjEQ5DJ0ShYEir6TCbO//atp9Y6ImNKe22nyIh+j83hGGmtnsQJlHC9JwYMtFdW7VHiscG1tFEdyHb4ylgSP6lXwb0D5FsBx/6/NQwMB+MYbPZVAxjgIHFIsNgGH+KXUiVLYMtgy1jNBCyTAgahsEw1Ephy2DLYMvQLqdYJiwTlgnLREuAZcIy0RoJRxOWCcuEZaIlsESZHA6H8nw+VzaDD31ucgdKS5zmLFVSgUwCQzzWkyp+66EiB8WzSTqiZoUh6spxV6ZKG8QDcJw3FJ7M8gA8OgxMrGmaLYpHXGo75qz4iwKDVvjgyT7u7uvr68nH4fkUz5leNxgMOlhRhWda6Wc6WHGea7uV7jreMEJU+ukGOXU85PWdYKSs8NGBkVo5rDoujWHM0QHkYyH0syP+pdb16E7CoPlAbD8QCoT8PTSpw7GpUshPMGRH6BIOY03M93vlxh654u9euWNS+e87mCV9XpHYvaPNY7PZnHJQcammXdIEXcZCwzQ+31c8YtWIf1w6hF0GsYTPEOtAG8hFtIf1lTuqZCZW4pQKxtQcR6NJrCwvBQRVdqxq3jHKM1KtIn3AuURFIxhSQnP/pYTUmzEqWLrwOQXYGoa8WJqrJXtqErCCuq77jkcRBFx62JxhyOkvfrSDdiXpUl8fCVBnL3rVkB/4NgYHgTEmo5B9YwLA8CsvfUekb/uVfEOCw6AXCLFLBQlkWQYZ7KkMYoT8qDAoGHQC4bdv8J7JeidFzjMbDOp4YeqqHxxJAYDesNlhjPmXtm0hh0toP2DjqJPCoNYyrBir0F2Rfx0MDHgpnUjcfDOYzyJkwpYhiZllQoAwDIahDnZsGWwZbBnaRJBlwjJhmbBMtARYJiwTrZFwNGGZsExYJloCLBOWidZIOJqwTJYvk99oQ2aizSFengAAAABJRU5ErkJggg=='
    }
  ];

  export default {
    name: 'PageWrapperCommon',
    props: {
      bgImageIdx: {
        type: Number,
        default: 0
      }
    },
    data:() => ({
      bgImages
    }),
    computed: {
      containerStyle() {
        if (this.bgImageIdx === 1) {
          return {
            background: 'url(' + this.bgImages[this.bgImageIdx].src + '), linear-gradient(180deg,#00697b,#003f4d)'
          };
        }
        return {
          backgroundImage: 'url(' + this.bgImages[this.bgImageIdx].src + ')'
        };
      },
      containerClass() {
        return this.bgImages[this.bgImageIdx].class;
      }
    }
  };
</script>

<style scoped>
.card-cover {
  background-attachment: fixed;
  background-position: top;
  border-radius: 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-size-cover {
  background-size: cover;
}

.card-cover-darken:after {
  position: absolute;
  content: '';
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.9;
}
</style>
