<template>
  <div>
    <v-row
    v-for="( block, index ) in blocks"
    :key="`dialog-${index}-${block}`"
    dense
    >
      <v-col
      v-if="index % 2"
      cols="4"
      />
      <v-col cols="8">
        <transition
        name="fade-transition"
        appear
        >
          <v-card
          v-if="index < step"
          :dark="!!(index % 2)"
          :light="!(index % 2)"
          :color="index % 2 ? 'primary darken-2' : 'white'"
          >
            <v-card-text
            class="py-2 font-weight-bold"
            :class="index % 2 ? 'white--text' : ''"
            >
              {{ block }}
            </v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'JokeView',
    props: {
      jokeBlocks: {
        type: Array,
        default: () => []
      },
      step: {
        type: Number,
        default: 1
      },
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      blocks() {
        if (!this.data)
          return [];

        return [
          'Knock Knock!',
          'Who\'s there?',
          this.data.name,
          `${this.data.name} who?`,
          this.data.answer ? `${this.data.answer.replace('{{name}}', this.data.name)}` : ''
        ];
      }
    }
  };
</script>

<style scoped>

</style>
