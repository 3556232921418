<template>
  <v-app>
    <generic-notification/>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
  import GenericNotification from '@/components/Notifications/Generic';
  window.addEventListener('appinstalled', () => {
    console.log('PWA was installed');
  });

  if (window.matchMedia('(display-mode: standalone)').matches
    || window.navigator.standalone === true) {
    window.appStandalone = true;
    window.addEventListener('resize', function() {
      const fixedWidth = 400;
      const fixedHeight = 700;
      setTimeout(() => {
        window.resizeTo(fixedWidth, fixedHeight);
      }, 150);
    });
  }

  export default {
    name: 'App',

    components: {
      GenericNotification
    },

    data: () => ({
    //
    }),

    created() {
      //check if browser version supports the api
      if ('getInstalledRelatedApps' in window.navigator) {
        window.navigator.getInstalledRelatedApps()
          .then(relatedApps => {
            relatedApps.forEach((rApp) => {
              //if your PWA exists in the array it is installed
              console.log(rApp.platform, rApp.url);
              window.installedApp = true;
            });
          });
      }
    }
  };
</script>
<style>
.adsbygoogle.splash {
  position: absolute;
  top: 0;
}
</style>
