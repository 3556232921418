const host = process.env.VUE_APP_HOSTNAME || 'localhost';
const nodeEnv = process.env.NODE_ENV || 'local';
const baseUrl = process.env.BASE_URL || 'http://localhost:8082/';

const FB_VAPID_KEY = process.env.VUE_APP_FB_VAPID_KEY;

/* eslint-disable no-console */
console.log(`Node Env: ${nodeEnv}`);
console.log(`API: ${host}`);
console.log(`BASE_URL: ${baseUrl}`);

module.exports = {
  debug: true,
  LOG_LEVEL: 'debug',
  FB_VAPID_KEY: FB_VAPID_KEY
};
