import stageBg from '../assets/images/bg_stage.jpg';
import storeAndroid from '../assets/images/google_play-icon.png';
import storeApple from '../assets/images/app_store-icon.png';

export const bgImage = stageBg;
export const androidImg = storeAndroid;
export const appleImg = storeApple;

export default {
  bgImage,
  androidImg,
  appleImg
};
