<template>
  <v-progress-circular
  class="v-btn--navigation navigation--spinner pointer"
  :rotate="360"
  :size="60"
  :width="6"
  :value="active ? ((15 - elapsed) / 15) * 100 : (defaultFull ? 100 : 0)"
  color="teal"
  @click="click"
  >
    <v-icon
    large
    dark
    :color="disabled ? 'transparent' : 'white'"
    >
      mdi-chevron-right
    </v-icon>
  </v-progress-circular>
</template>

<script>
  export default {
    name: 'CountDownButton',
    props: {
      elapsed: {
        type: Number,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: false
      },
      defaultFull: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      click() {
        this.$emit('click');
      }
    }
  };
</script>

<style scoped>

</style>
