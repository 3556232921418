<template>
  <v-speed-dial
  v-model="fab"
  bottom
  left
  direction="right"
  class="v-btn--navigation v-btn--share"
  >
    <template v-slot:activator>
      <v-btn
      v-model="fab"
      color="transparent"
      dark
      fab
      text
      >
        <v-icon
        v-if="fab"
        color="white"
        >
          mdi-close
        </v-icon>
        <v-icon
        v-else
        color="white"
        >
          mdi-share-variant
        </v-icon>
      </v-btn>
    </template>
    <template v-for="shareItem in shareItems">
      <v-btn
      :key="shareItem.icon"
      fab
      dark
      small
      color="#1976d299"
      @click="shareItem.action"
      >
        <v-icon>{{ shareItem.icon }}</v-icon>
      </v-btn>
    </template>
  </v-speed-dial>
</template>

<script>
  import shortLink from '@/utils/api/shortlink.util';
  export default {
    name: 'ShareButton',
    props: {
      link: { type: String, default: '' }
    },
    data: function() {
      return {
        fab: false,
        message: 'Check out this Knock Knock joke! ;)',
        shareShortLink: this.link,
        shareItems: [
          {
            icon: 'mdi-facebook',
            action: this.shareFacebook
          },
          {
            icon: 'mdi-whatsapp',
            action: this.shareWhatsapp
          },
          {
            icon: 'mdi-twitter',
            action: this.shareTwitter
          }
          // {
          //   icon: 'mdi-link',
          //   action: this.share
          // }
        ]
      };
    },
    computed: {
      isNavigatorShare() {
        return !!window.navigator.share;
      }
    },
    watch: {
      fab(val) {
        if (val) {
          shortLink(this.link)
            .then((link) => {
              this.shareShortLink = link;
            });
        }
      }
    },
    methods: {
      share() {
        if (this.isNavigatorShare) {
          window.navigator.share({
            title: 'web.dev',
            text: 'Check out web.dev.',
            url: 'https://web.dev/'
          });
          // .then(() => console.log('Successful share'))
          // .catch((error) => console.log('Error sharing', error));
        }
      },
      shareWhatsapp() {
        this.$emit('share');
        window.open(`whatsapp://send?text=%0a${this.message}%0a%0a${this.shareShortLink}`);
      },
      shareFacebook() {
        this.$emit('share');
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.shareShortLink}&t=${this.message}`, '_blank');
      },
      shareTwitter() {
        this.$emit('share');
        window.open(`http://twitter.com/share?text=${this.message}%0a%0a&url=${this.shareShortLink}#funny#knockknock`, '_blank');
      }
    }
  };
</script>

<style scoped>

</style>

<style>
.v-btn--share {
  margin-bottom: 20px;
  bottom: 0;
  position: absolute;
}
.v-btn--share .v-speed-dial__list {
  padding: 0 !important;
  /*background: #1976d299;*/
  /*border-radius: 3px;*/
}
</style>
