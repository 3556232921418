import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import './plugins/notifications';

Vue.config.productionTip = false;
Vue.use(VueRouter);

import './utils/firebase.util';
import './config';
import PageWrapperCommon from './components/Templates/PageWrapperCommon';
import FullScreenDialog from './components/Templates/FullScreenDialog';
Vue.component('page-wrapper-common', PageWrapperCommon);
Vue.component('full-screen-dialog', FullScreenDialog);

import jokeScreen from './screens/JokeScreen';
import './registerServiceWorker';

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Joke',
      component: jokeScreen
    }
  ]
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
