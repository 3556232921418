import firebase from 'firebase';
import shortLink from '@/utils/api/shortlink.util';

const db = firebase.firestore();

const collectionRef = db.collection('jokes');

const list = () => {
  return new Promise((resolve) => {
    collectionRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      for (let i = data.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [data[i], data[j]] = [data[j], data[i]];
      }

      return resolve(data);
    });
  });
};

const create = (payload) => {
  return collectionRef.doc().set({
    ...payload,
    created: firebase.firestore.FieldValue.serverTimestamp()
  })
    .catch((error) => { console.error('Error writing document: ', error); });
};

const get = (jokeId) => {
  return new Promise((resolve) => {
    return collectionRef.doc(jokeId).get()
      .then((snapshot) => {
        const data = {
          id: snapshot.id,
          ...snapshot.data()
        };

        return resolve(data);
      });
  });
};

const shareUrl = async(jokeId) => {
  try {
    const { shortLink: rsp } = await shortLink(`https://app-knock-knock.web.app/#/?j=${jokeId}`);

    return rsp;
  } catch (err) {
    return `https://app-knock-knock.web.app/#/?j=${jokeId}`;
  }
};

const testJokeShortLinks = () => {
  shortLink('https://app-knock-knock.web.app/#/?j=0NZWCJyVyyGfjLxACIq5')
    .then(data => {
      console.log(data.shortLink);
    });
};

const injectJokes = () => {
  const jokes = [
    {
      name: 'Nobel',
      answer: 'Nobel... That’s why I knocked!'
    },
    {
      name: 'Tank',
      answer: 'You’re welcome.'
    },
    {
      name: 'Annie',
      answer: 'Annie thing you can do, I can do better!'
    },
    {
      name: 'Hal',
      answer: 'Hal you know if you don\'t open the door?'
    },
    {
      name: 'Alice',
      answer: '{{name}} fair in love and war'
    },
    {
      name: 'Hawaii',
      answer: 'I\'m good. Hawaii you?'
    },
    {
      name: 'Woo',
      answer: 'Glad you\'re excited, too!'
    },
    {
      name: 'Who',
      answer: 'I didn\'t know you were an owl!'
    },
    {
      name: 'Water',
      answer: '{{name}} you doing telling jokes right now? Don\'t you have things to do?'
    },
    {
      name: 'Iran',
      answer: 'Iran here. I\'m tired!'
    },
    {
      name: 'Amos',
      answer: 'A mosquito. Look, right there!'
    },
    {
      name: 'Thermos',
      answer: 'Thermos be a better way to get to you.'
    },
    {
      name: 'Razor',
      answer: 'Razor hands, this is a stick up!'
    },
    {
      name: 'Alec',
      answer: 'Alectricity. BUZZ!'
    },
    {
      name: 'Stopwatch',
      answer: 'Stopwatch you\'re doing and let me in!'
    },
    {
      name: 'Icy',
      answer: '{{name}} you looking at me!'
    },
    {
      name: 'Alex',
      answer: 'Alex-plain later!'
    },
    {
      name: 'Anee',
      answer: '{{name}} one you like!'
    },
    {
      name: 'Dishes',
      answer: 'Dish is a nice place!'
    },
    {
      name: 'Norma Lee',
      answer: 'Norma Lee I don\'t knock on random doors, but I had to meet you!'
    },
    {
      name: 'Yukon',
      answer: '{{name}} say that again!'
    },
    {
      name: 'Viper',
      answer: '{{name}} nose, it’s running!'
    },
    {
      name: 'Roach',
      answer: '{{name}} you a text. Did you get it?'
    },
    {
      name: 'Justin',
      answer: '{{name}} time for dinner.'
    },
    {
      name: 'Yukon',
      answer: '{{name}} say that again!'
    },
    {
      name: 'Sadie',
      answer: '{{name}} magic word and see what is revealed!'
    },
    {
      name: 'I am',
      answer: 'Wait, you don\'t know who you are?'
    },
    {
      name: 'Amish',
      answer: 'You\'re not a shoe!'
    }
  ];

  jokes.forEach(x => {
    create(x)
      .catch();
  });
};

export default {
  list,
  create,
  get,
  injectJokes,
  shareUrl,
  testJokeShortLinks
};
