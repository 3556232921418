<template>
  <full-screen-dialog
  v-model="show"
  dark
  :actions="dialogActions"
  @save="submit"
  >
    <v-container
    fluid
    class="px-0 py-0"
    >
      <v-layout
      row
      wrap
      class="mb-3"
      >
        <v-col
        v-if="title"
        cols="12"
        class="px-3 pb-3"
        >
          <v-row justify="space-between">
            <v-col
            class="white--text font-weight-bold title py-3"
            >
              {{ title }}
            </v-col>
          </v-row>
          <v-divider class="mt-3"/>
        </v-col>

        <template v-for="field in fields">
          <v-flex
          v-if="field.type !== 'joke-view'"
          :key="field.key"
          class="px-3 pb-3"
          sm12
          >
            <v-text-field
            v-model="dialogForm[field.key]"
            :label="field.options.label"
            flat
            hide-details
            :append-icon="field.options['append-icon']"
            autocomplete="off"
            />
          </v-flex>
          <v-flex
          v-else
          :key="`markup-${field.key}`"
          class="px-10 pt-15"
          sm12
          >
            <joke-view
            :data="{
              name: dialogForm['name'] || '[Name]',
              answer: dialogForm['answer'] || '[Answer goes here]'
            }"
            :step="5"
            />
          </v-flex>
        </template>
        <slot/>
      </v-layout>
    </v-container>
  </full-screen-dialog>
</template>

<script>

  import JokeView from '@/components/JokeView';
  export default {
    name: 'ComposeForm',
    components: {
      JokeView
    },
    props: {
      title: {
        type: String,
        default: null
      },
      value: {
        type: Boolean
      },
      fields: {
        type: Array,
        default: () => []
      },
      defData: {
        type: Object,
        default: () => {}
      },
      defaultDate: {
        type: String,
        default: new Date().toISOString().substr(0, 10)
      }
    },
    data: function() {
      return {
        dialogForm: {},
        dialogActions: [
          {
            label: 'cancel',
            onClick: () => { this.$emit('input', false); }
          },
          {
            label: 'save',
            onClick: this.submit,
            color: 'primary'
          }
        ]
      };
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
          if (!value)
            this.$emit('onClose');
        }
      }
    },
    watch: {
      value(val) {
        if (val)
          this.dialogForm = { ...this.defData };
      }
    },
    methods: {
      submit() {
        this.$emit('submit', this.dialogForm);
      }
    }
  };
</script>

<style scoped>

</style>
