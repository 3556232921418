<template>
  <v-dialog
  v-model="show"
  fullscreen
  v-bind="$attrs"
  hide-overlay
  transition="dialog-bottom-transition"
  >
    <v-card dark>
      <v-toolbar
      dark
      color="primary darken-4"
      >
        <v-btn
        icon
        dark
        @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn
          dark
          icon
          @click="save"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <slot/>
        <v-overlay
        absolute
        :value="status === 'in_progress'"
        >
          <v-progress-circular
          indeterminate
          size="64"
          />
        </v-overlay>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'FullScreenDialog',
    props: {
      value: Boolean,
      title: { type: String, default: '' },
      status: { type: String, default: '' },
      saveText: {
        type: String,
        default: ''
      }
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
          if (!value)
            this.$emit('onClose');
        }
      }
    },
    methods: {
      save() {
        this.$emit('save');
      }
    }
  };
</script>

<style scoped>

</style>
