import firebase from 'firebase';

const db = firebase.firestore();

const collectionRef = db.collection('ratings');

const create = (payload) => {
  return collectionRef.add({
    ...payload,
    created: firebase.firestore.FieldValue.serverTimestamp()
  })
    .catch((error) => { console.error('Error writing document: ', error); });
};

const remove = (id) => {
  return collectionRef.doc(id).delete()
    .catch((error) => { console.error('Error deleting document: ', error); });
};

export default {
  create,
  remove
};
