<template>
  <page-wrapper-common>
    <v-container
    fluid
    style="max-width: 600px"
    >
      <v-container
      fluid
      class="text-justify py-0 white--text px-0 mb-15"
      >
        <v-row no-gutters>
          <v-col
          class="text-h5"
          >
            <a
            class="white--text"
            @click="addNewJoke"
            >
              <v-fade-transition>
                <div v-if="step > 4">{{ title }}</div>
              </v-fade-transition>
              <v-fade-transition>
                <lottie-vue
                v-if="step < 4 && step > 0"
                :options="lottieOptions"
                :height="30"
                :width="70"
                @animCreated="handleAnimation"
                />
              </v-fade-transition>
            </a>
          </v-col>
          <v-col
          cols="3"
          class="text-end"
          >
            <v-btn
            fab
            text
            small
            dark
            :ripple="false"
            @click="autoPlay = !autoPlay"
            >
              <v-icon>
                {{ autoPlay ? 'mdi-pause' : 'mdi-play' }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider
        dark
        class="my-2"
        />
      </v-container>

      <joke-view
      :data="currentJoke"
      :step="step"
      />

      <div
      v-if="step > jokeBlocksLength && jokeBlocksLength"
      class="text-center mt-15"
      >
        <v-divider
        dark
        class="my-10"
        />
        <rating-block
        :visible="!isRatingProcessing"
        :joke-rating="jokeRating"
        @rate="rate"
        />
      </div>
    </v-container>
    <count-down-button
    :active="(!jokeRating || !!autoPlay)"
    :elapsed="countdown"
    :default-full="!!jokeRating"
    :disabled="nextDisabled"
    @click="next"
    />

    <compose-form
    v-model="newJokeDialog"
    title="Submit your joke"
    :fields="jokeDialogFields"
    @submit="saveNewJoke"
    />
    <share-button
    :link="`https://app-knock-knock.web.app/#/?j=${currentJoke.id}`"
    @share="autoPlay = false"
    />
  </page-wrapper-common>
</template>

<script>
  import jokesApi from '@/utils/api/jokes';
  import ratingsApi from '@/utils/api/ratings';
  import { androidImg, appleImg } from '@/utils/assets.util';
  import ComposeForm from '@/components/Templates/ComposeForm';
  import LottieVue from '@/components/LottieVue';
  import * as animationData from '../assets/animations/LoaderDots.json';
  import ShareButton from '@/components/ShareButton';
  import JokeView from '@/components/JokeView';
  import RatingBlock from '@/components/RatingBlock';
  import CountDownButton from '@/components/CountDownButton';

  const COUNTDOWN_SECONDS = 15;

  export default {
    name: 'JokeScreen',
    components: {
      CountDownButton,
      RatingBlock,
      JokeView,
      ShareButton,
      LottieVue,
      ComposeForm
    },
    data: function() {
      return {
        step: 1,
        ready: false,
        autoPlay: true,
        stepInterval: null,
        jokeIndex: 0,
        jokes: [],
        joke: {},
        jokeRating: null,
        countdown: COUNTDOWN_SECONDS,
        countdownInterval: null,
        isRatingProcessing: false,
        newJokeDialog: false,
        preview: false,
        jokeDialogData: {},
        jokeDialogFields: [
          {
            key: 'name',
            options: {
              'label': 'Name',
              'append-icon': 'mdi-account'
            },
            type: 'v-text-field'
          },
          {
            key: 'answer',
            options: {
              'label': 'Answer',
              'append-icon': 'mdi-subtitles-outline'
            },
            type: 'v-text-field'
          },
          {
            key: 'preview',
            type: 'joke-view',
            options: {}
          }
        ],
        lottieOptions: {
          animationData: animationData.default
        }
      };
    },
    computed: {
      jokeBlocksLength() {
        return !this.currentJoke.id ? 0 : 5;
      },
      currentJoke() {
        return this.jokes[this.jokeIndex] || {};
      },
      title() {
        return this.currentJoke && this.currentJoke.name ? this.currentJoke.name : '';
      },
      nextDisabled() {
        return (this.step < this.jokeBlocksLength);
      }
    },
    watch: {
      autoPlay(val) {
        if (val)
          this.startCountdown();
        else
          this.pauseCountdown();
      }
    },
    created() {
      const jokeId = this.$route.query.j;
      if (jokeId) {
        jokesApi.get(jokeId)
          .then(rsp => {
            this.jokes = [rsp];
            this.preview = !!this.$route.query.j;
          });
      } else {
        jokesApi.list()
          .then(rsp => {
            this.jokes = rsp;
          });
      }

      setTimeout(() => {
        this.init();
      }, 500);
    },
    methods: {
      init() {
        clearInterval(this.stepInterval);
        this.jokeRating = null;
        this.stepInterval = setInterval(() => {
          this.step += 1;
          if (this.step > this.jokeBlocksLength)
            clearInterval(this.stepInterval);
          else if (this.step === 2 && this.autoPlay)
            this.startCountdown();
        }, 1350);
      },
      handleAnimation: function(anim) {
        this.anim = anim;
      },
      next() {
        if (this.step < this.jokeBlocksLength)
          return;

        this.pauseCountdown();
        if (this.preview) {
          if (!window.appStandalone) {
            const text = `
                        For more Jokes - get the app!
                        <div>
                          <a href="https://play.google.com/store/apps/details?id=com.octa.code.knockknock"><img style="display: inline-block; width: 45%" src="${androidImg}"/></a>
                          <img style="display: inline-block; width: 45%; opacity: 0.25" src="${appleImg}"/>
                        </div>
                      `;
            return this.$notify({
              text: text,
              group: 'generic',
              duration: 8000
            });
          }
          window.location.href = '/';
          return;
        }

        this.resetCountdown();

        this.step = 0;

        if (this.jokeIndex + 1 >= this.jokes.length)
          this.jokeIndex = 0;
        else
          this.jokeIndex += 1;

        this.init();
      },
      resetCountdown(val) {
        this.countdown = val || COUNTDOWN_SECONDS;
        this.startCountdown();
      },
      pauseCountdown() {
        clearInterval(this.countdownInterval);
      },
      startCountdown() {
        this.pauseCountdown();
        if (this.autoPlay) {
          this.countdownInterval = setInterval(() => {
            this.countdown -= 1;
            if (this.countdown < 0)
              this.next();
          }, 1000);
        }
      },
      rate(val) {
        this.isRatingProcessing = true;

        if (!this.jokeRating) {
          ratingsApi.create({
            'jokeRef': `/jokes/${this.currentJoke.id}`,
            'rating': val
          })
            .then(rsp => {
              this.jokeRating = {
                doc: rsp.id,
                val
              };
            })
            .finally(() => {
              this.resetCountdown(7);
              this.isRatingProcessing = false;
            });
        } else {
          ratingsApi.remove(this.jokeRating.doc)
            .then(() => {
              this.jokeRating = null;
            })
            .finally(() => {
              this.resetCountdown(7);
              this.isRatingProcessing = false;
            });
        }
      },
      addNewJoke() {
        if (this.$route.query.admin) {
          this.autoPlay = false;
          this.newJokeDialog = true;
        }
      },
      saveNewJoke(payload) {
        jokesApi.create(payload)
          .then(() => {
            jokesApi.list()
              .then(rsp => {
                this.jokes = rsp;
                const [joke] = rsp;
                this.joke = joke;
              });
          });
        this.newJokeDialog = false;
      }
    }
  };
</script>

<style scoped>
.v-btn--navigation {
  bottom: 0;
  position: absolute;
  margin: 0 0 100px 0;
}
.navigation--spinner {
  right: 15px;
  margin-bottom: 70px;
}
.v-btn--share {
  margin-bottom: 20px;
}
</style>
