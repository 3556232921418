const shortLink = (url) => {
  return fetch('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBGuLlXRNpNEopqMPrIi7pKR2Y0K7dOmMM', {
    method: 'POST',
    body: JSON.stringify({
      'dynamicLinkInfo': {
        'domainUriPrefix': 'https://lols.page.link',
        'link': url
        // 'androidInfo': {
        //   'androidPackageName': 'com.example.android'
        // },
        // 'iosInfo': {
        //   'iosBundleId': 'com.example.ios'
        // }
      },
      'suffix': { 'option': 'SHORT' }
    })
  })
    .then(rsp => rsp.json())
    .then(data => {
      return data.shortLink;
    });
};

export default shortLink;
