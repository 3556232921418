<template>
  <notifications
  group="generic"
  position="bottom center"
  >
    <template
    slot="body"
    slot-scope="props"
    >
      <v-card
      dark
      :color="props.item.data && props.item.data.color"
      class="mx-3 mb-2"
      >
        <v-card-text>
          <div class="d-flex">
            <div class="px-1">
              <div
              class="rotate"
              @click="props.close"
              >
                <v-icon
                v-if="props.item.data && props.item.data.icon"
                small
                >
                  {{ props.item.data.icon }}
                </v-icon>
              </div>
            </div>
            <div
            class="flex-fill"
            v-html="props.item.text"
            />
            <v-btn
            v-if="props.item.data && props.item.data.showCloseButton"
            text
            color="secondary"
            cy-data="closeSnackbar"
            @click="props.close"
            >
              {{ $vuetify.lang.t('$vuetify.close') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </notifications>
</template>

<script>
// GenericNotification - display a global snackbar, triggers whenever this.$notify called
  export default {
    name: 'GenericNotification'
  };
</script>

<style scoped>
    .vue-notification-group {
        width: 100% !important;
        left: 0 !important;
    }

    .rotate {
      /*animation-name: bounce-in;*/
      animation-iteration-count: infinite;
      animation: rotate 1.5s infinite;
      animation-delay: .7s;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(45deg);
      }
      50% {
        transform: rotate(80deg);
      }
      75% {
        transform: rotate(-10deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
</style>
